.ant-layout {
  background: transparent;
}

.ant-layout-sider {
  background-color: $menu-dark-bg;
}

// ITSB
.searchRow-mobile {
  .ant-col {
    width: 100%;
  }

  .ant-select {
    display: block;
  }

  .ant-picker {
    width: 100%;
  }

  .ant-btn {
    width: 100%;
  }

  .ant-form-item {
    margin-right: 0;
    margin-bottom: 0;
  }
}

.searchRow {
  .ant-form-item {
    margin-bottom: 0px;
  }
}

.ant-collapse-ghost {
  background-color: transparent !important;
  border: 0 !important;
}

.itsb {
  .ant-collapse-header {
    padding: 0 !important;
  }

  .ant-collapse-item {
    border: 0 !important;
  }

  .ant-radio-button-wrapper-disabled {
    color: rgba(34, 34, 34, 0.25) !important;
  }

  &.ant-btn:focus {
    background: #fff !important;
    border-color: #e2e2e2 !important;
    color: #222222 !important;
  }
}

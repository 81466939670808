@import './theme';

// Body
//
// Settings for the `<body>` element.
$body-bg: #f0f0f0 !default;
$body-color: $gray !default;

// Template
//
// Configuration of template
$header-height: 70px;
$header-box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
$side-nav-width: 280px;
$side-nav-width-laptop: 230px;
$side-nav-folded: 80px;
$side-nav-box-shadow: 0 1px 4px -1px rgba(0, 0, 0, 0.15);
$top-nav-height: 55px;
$layout-content-gutter: 25px;
$layout-content-gutter-sm: 15px;
$footer-height: 60px;
$content-height-offset: $header-height + $footer-height;
$content-max-width: 1400px;

// Links
//
// Style anchor elements.
$link-color: $primary !default;
$link-decoration: none !default;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.
$enable-flex: true !default;
$enable-rounded: true !default;
$enable-shadows: false !default;
$enable-gradients: false !default;
$enable-transitions: true !default;
$enable-hover-media-query: false !default;
$enable-grid-classes: true !default;
$enable-print-styles: true !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
) !default;

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1400px,
) !default;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.
$grid-columns: 12 !default;
$grid-gutter-width-base: 18px !default;
$grid-gutter-widths: (
  xs: $grid-gutter-width-base,
  sm: $grid-gutter-width-base,
  md: $grid-gutter-width-base,
  lg: $grid-gutter-width-base,
  xl: $grid-gutter-width-base,
) !default;

// Spacing
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge(
  (
    0: 0,
    1: (
      $spacer * 0.25,
    ),
    2: (
      $spacer * 0.5,
    ),
    3: $spacer,
    4: (
      $spacer * 1.5,
    ),
    5: (
      $spacer * 3,
    ),
  ),
  $spacers
);

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$sizes: map-merge(
  (
    25: 25%,
    50: 50%,
    75: 75%,
    100: 100%,
    auto: auto,
  ),
  $sizes
);

// Fonts
//
// Font-weight, line-height, font-size
$font-family-sans-serif: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
  'PingFang SC', 'Hiragino Sans GB', 'Microsoft YaHei', 'Helvetica Neue',
  Helvetica, Arial, sans-serif;
$font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
  'Liberation Mono', 'Courier New', monospace !default;
$font-family-base: $font-family-sans-serif !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-semibold: 500 !default;
$font-weight-bold: bold !default;
$font-weight-base: $font-weight-normal !default;
$font-weight-bolder: bolder !default;

$line-height-base: 1.5 !default;

$font-size-base: 0.875rem !default; //14px
$font-size-xxl: $font-size-base + 1rem !default; //30px
$font-size-xl: $font-size-base + 0.5rem !default; //22px
$font-size-lg: $font-size-base + 0.375rem !default; //20px
$font-size-md: $font-size-base + 0.175rem !default; //16px
$font-size-sm: $font-size-base - 0.125rem !default; //12px
$font-size-xs: $font-size-base - 0.25rem !default; //10px

$display1-size: 6rem !default;
$display2-size: 5.5rem !default;
$display3-size: 4.5rem !default;
$display4-size: 3.5rem !default;

$display1-weight: 300 !default;
$display2-weight: 300 !default;
$display3-weight: 300 !default;
$display4-weight: 300 !default;

$display-line-height: 1.1 !default;

$lead-font-size: 1.25rem !default;
$lead-font-weight: 300 !default;

$small-font-size: 80% !default;

$text-muted: rgba($gray-light, 0.7) !default;

// Box Shadow
//
// Box Shadow Size
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.15) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;

// Border
//
// Define border properties
$border-color: #e2e2e2 !default;
$border-radius: 2px !default;
$border-radius-lg: 0.3rem !default;
$border-radius-sm: 0.25rem !default;
$border-radius-xs: 0.125rem !default;
$border-radius-circle: 50% !default;
$border-radius-round: 50px !default;
$border-width: 1px !default;

// Buttons
//
// define buttons text, background and border color.
$btn-padding-x: 1.25rem !default;
$btn-padding-y: 0.5rem !default;
$btn-size: 2.5rem !default;
$btn-line-height: 1.5 !default;

$btn-font-weight: $font-weight-normal !default;
$btn-box-shadow: inset 0 1px 0 rgba($white, 0.15), 0 1px 1px rgba($black, 0.075) !default;
$btn-focus-box-shadow: 0 0 0 2px rgba($primary, 0.25) !default;
$btn-active-box-shadow: inset 0 3px 5px rgba($black, 0.125) !default;

$btn-primary-color: $white !default;
$btn-primary-bg: $primary !default;
$btn-primary-border: $btn-primary-bg !default;

$btn-link-disabled-color: $gray-light !default;

$btn-padding-x-sm: 15px !default;
$btn-padding-y-sm: 0px !default;

$btn-padding-x-lg: 1.5rem !default;
$btn-padding-y-lg: 0.75rem !default;

$btn-size-sm: 1.9rem !default;
$btn-size-lg: 3rem !default;

$btn-block-spacing-y: 0.5rem !default;
$btn-toolbar-margin: 0.5rem !default;

$btn-disabled-color: $gray-lightest !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius: $border-radius !default;
$btn-border-radius-lg: $border-radius-lg !default;
$btn-border-radius-sm: $border-radius-sm !default;

$btn-transition: all 0.2s ease-in-out !default;

// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.
$state-success-text: $success !default;
$state-success-bg: rgba($success, 0.1) !default;
$state-success-border: rgba($success, 0.15) !default;

$state-info-text: $info !default;
$state-info-bg: rgba($info, 0.1) !default;
$state-info-border: rgba($info, 0.15) !default;

$state-warning-text: $warning !default;
$state-warning-bg: rgba($warning, 0.1) !default;
$mark-bg: $state-warning-bg !default;
$state-warning-border: rgba($warning, 0.15) !default;

$state-danger-text: $danger !default;
$state-danger-bg: rgba($danger, 0.1) !default;
$state-danger-border: rgba($danger, 0.15) !default;

// Input
//
// Input element config
$input-padding-x: 1rem !default;
$input-padding-y: 0.6rem !default;
$input-line-height: 1.5 !default;
$input-theme-color: $primary !default;
$input-font-size: $font-size-base !default;
$input-height: (
  ($font-size-base * $input-line-height) + ($input-padding-y * 2)
) !default;

$input-padding-x-sm: 0.6rem !default;
$input-padding-y-sm: 0.2rem !default;
$input-font-size-sm: 0.75rem !default;
$input-border-radius-sm: $border-radius-sm !default;
$input-height-sm: (
  ($font-size-base * $input-line-height) + ($input-padding-y-sm * 2)
) !default;

$input-padding-x-lg: 1rem !default;
$input-padding-y-lg: 0.65rem !default;
$input-font-size-lg: 1rem !default;
$input-border-radius-lg: $border-radius-lg !default;
$input-height-lg: (
  ($font-size-base * $input-line-height) + ($input-padding-y-lg * 2.28)
) !default;

$input-bg: $white !default;
$input-bg-disabled: $gray-lighter !default;

$input-color: $gray !default;
$input-border-color: $border-color !default;
$input-btn-border-width: $border-width !default; // For form controls and buttons
$input-box-shadow: inset 0 1px 1px rgba($black, 0.075) !default;

$input-border-radius: $border-radius !default;

$input-bg-focus: $input-bg !default;
$input-border-focus: lighten($primary, 25%) !default;
$input-box-shadow-focus: $input-box-shadow, rgba($input-border-focus, 0.6) !default;
$input-color-focus: $input-color !default;

$input-color-placeholder: rgba($gray-light, 0.4) !default;

$input-error-color: $state-danger-text !default;
$input-warning-color: $state-warning-text !default;
$input-success-color: $state-success-text !default;

$textarea-min-height: 32px !default;

// Select
//
// Select element config
$select-height: $input-height !default;
$select-height-sm: $input-height-sm !default;
$select-height-lg: $input-height-lg !default;
$select-tag-color: #f5f5f5;
$select-tag-margin-y: 0.4375rem !default;
$select-tag-margin-x: 0.4375rem !default;
$select-tag-height: (
  ($font-size-base * $input-line-height) + ($input-padding-y * 2) -
    ($select-tag-margin-y * 2)
) !default;
$select-tag-height-sm: (
  ($font-size-base * $input-line-height) + ($input-padding-y-sm * 2.2) -
    ($select-tag-margin-y * 2)
) !default;
$select-tag-height-lg: (
  ($font-size-base * $input-line-height) + ($input-padding-y-lg * 2) -
    ($select-tag-margin-y * 2)
) !default;
$select-tag-line-height: 1.4375rem !default;
$select-tag-line-height-sm: 1rem !default;
$select-tag-line-height-lg: 2rem !default;
$select-item-min-height: 2rem !default;
$select-item-line-height: 1.375rem !default;
$select-item-spacer-x: 0.4375rem !default; // 7px
$select-item-spacer-y: 0.75rem !default; // 12px
$select-item-color: $gray-light !default;
$select-item-hover: #fafafa !default;
$select-item-selected: #f6f6f6 !default;

// Date Picker
//
// Date Picker config
$date-picker-height: $select-height !default;
$date-picker-height-sm: $select-height-sm !default;
$date-picker-height-lg: $select-height-lg !default;

// Cascader
//
// Cascader element config
$cascader-menu-min-width: 7.5rem !default;
$cascader-menu-height: 11.25rem !default;
$cascader-menu-item-spacing-y: 7px !default;
$cascader-menu-item-spacing-x: 12px !default;
$cascader-menu-item-line-height: 1.375rem !default;

// Checkbox
//
// Checkbox element config
$checkbox-theme-color: $primary !default;
$checkbox-width-height: 1.25rem !default;
$checkbox-line-height: 1 !default;
$checkbox-top-adjust: -0.09em !default;
$checkbox-position-left: 0.325rem !default;
$checkbox-position-top: 0.535rem !default;
$checkbox-disable-bg: $gray-lighter !default;
$checkbox-border-radius: 0.25rem !default;

// Transfer
//
// Transfer element config
$transfer-theme-color: $primary !default;
$transfer-width: 15rem !default;
$transfer-height: 17.1875rem !default;

// Radio
//
// Radio element config
$radio-theme-color: $primary !default;
$radio-width-height: 1.25rem !default;
$radio-gutter: 0.625rem !default;
$radio-dot-size: 0.625rem !default;
$radio-position-left: 0.1875rem !default;
$radio-position-top: 0.1875rem !default;
$radio-disable-bg: $gray-lighter !default;
$radip-btn-padding-x: 1rem !default;

// Calendar
//
// Define calendar spacing, colors, border & etc
$calendar-date-theme-color: $primary !default;
$calendar-date-x: 2rem !default;
$calendar-date-y: 2rem !default;
$calendar-date-line-height: 2rem - 0.125rem !default;
$calendar-date-text-color: $body-color !default;
$calendar-year-month-height: 1.875rem !default;

$picker-calendar-value-size: 1.75rem !default;
$picker-calendar-theme-color: $primary !default;
$picker-calendar-selected-bg: rgba($picker-calendar-theme-color, 0.1) !default;
$picker-calendar-border-color: $border-color !default;
$picker-calendar-hover-bg: $gray-lightest !default;
$picker-calendar-mini-cell-size: 1.875rem !default;

// Rate
//
// Rate config
$rate-font-size: 1.25rem !default;
$rate-color: $warning !default;

// Slider
//
// Define slider properties
$slider-height: 0.75rem !default;
$slider-gutter: 0.9375rem 0.3125rem !default;
$slider-rail-bg: $gray-lighter !default;
$slider-rail-height: 0.3125rem !default;
$slider-rail-radius: $border-radius-sm !default;
$slider-track-height: 0.3125rem - 0.0625rem !default;
$slider-theme-color: $primary !default;
$slider-ticker-size: 1.125rem !default;
$slider-dot-size: 0.5rem !default;
$slider-ticker-top-adjust: -0.4375rem !default;
$slider-ticker-left-adjust: -0.4375rem !default;
$slider-hover-opacity: 0.8 !default;

// Switch
//
// Define switch properties
$switch-theme-color: $primary !default;
$switch-height: 25px !default;
$switch-min-width: $switch-height * 2 !default;
$switch-size: $switch-height - 4px !default;
$switch-font-size: $switch-height - 10px !default;
$switch-loading-size: $switch-size - 6px !default;
$switch-height-sm: 15px !default;
$switch-min-width-sm: $switch-height-sm * 2 !default;
$switch-size-sm: $switch-height-sm - 4px !default;
$switch-loading-size-sm: $switch-size-sm - 3px !default;

// Upload
//
// Define upload properties
$upload-theme-color: $primary !default;
$upload-picture-size: 6.875rem !default;
$upload-thumbnail-size: 3rem !default;
$upload-picture-bg: $white !default;
$upload-picture-gutter: 0.625rem !default;
$upload-list-hover: $gray-lightest !default;

// Dropdowns
//
// Dropdown menu container and contents.
$dropdown-bg: $white !default;
$dropdown-bg-hover: $select-item-hover !default;
$dropdown-border-color: $border-color !default;
$dropdown-item-text: $gray !default;
$dropdown-item-text-hover: $gray-dark !default;
$dropdown-padding-y: 0.5rem !default;
$dropdown-padding-x: 1.25rem !default;
$dropdown-margin: 0.625rem !default;
$dropdown-min-width: 10rem !default;
$dropdown-max-height: 15.625rem !default;
$dropdown-shadow: 0 2px 8px rgba(0, 0, 0, 0.15) !default;

// Menu
//
// Define colors theme color for menu
$menu-spacing-y: 2.8125rem;
$menu-text: rgba($gray-dark, 0.85) !default;
$menu-dark-bg: #193550 !default;
$menu-dark-bg-sub: #181f29 !default;
$menu-dark-text: #99abb4 !default;

//Pagination
//
// Pagination elements config
$pagination-theme-color: #344372 !default;
$pagination-size: 2rem !default;
$pagination-size-sm: 1.5rem !default;

// Steps
//
// Steps elements config
$step-theme-colors: $primary !default;
$step-icon-size: 2rem !default;
$step-icon-size-sm: 1.5rem !default;
$step-line-width: 1px !default;

// Avatar
//
// Define avatar size & default background
$avatar-bg: $gray-lighter !default;
$avatar-size: 2.5rem !default;
$avatar-size-sm: 1.5rem !default;
$avatar-size-lg: 3rem !default;
$avatar-icon: 1.125rem !default;
$avatar-icon-sm: 0.875rem !default;
$avatar-icon-lg: 1.75rem !default;

// Badge
//
// Define Badge size & colors
$badge-count-bg: $danger !default;
$badge-count-height: 1.25rem !default;
$badge-count-font-size: $font-size-base !default;
$badge-dot-size: 0.4375rem !default;
$badge-dot-processing-bg: $info !default;

// Collapse
//
// Collapse config
$collapse-border: $border-color !default;
$collapse-bg: #fafafa !default;
$collapse-header-spacing: 1rem 2.5rem 1rem 2.5rem !default;
$collapse-header-color: $gray-dark !default;
$collapse-header-size: $font-size-md !default;
$collapse-content-spacing: 1rem !default;

// Cards
//
// Cards config
$card-spacer-x: 1.25rem !default;
$card-spacer-y: 1.25rem !default;
$card-border-radius: $border-radius !default;
$card-border-color: $border-color !default;
$card-head-bg: $white !default;
$card-bg: $white !default;
$card-head-min-height: 2.1875rem !default;
$card-hover-shadow: 0 2px 8px rgba($black, 0.09) !default;
$card-action-bg: #fafafa;
$card-img-overlay-padding: 1.25rem !default;

// Empty
//
// Empty config
$empty-line-height: 1.375rem !default;
$empty-font-size: $font-size-base !default;

// List
//
// List config
$list-spacer-x: 1.5rem !default;
$list-spacer-y: 0.9375rem !default;
$list-spacer-x-sm: 1rem !default;
$list-spacer-y-sm: 0.625rem !default;
$list-spacer-x-lg: 1.5rem !default;
$list-spacer-y-lg: 1rem !default;
$list-item-hover: $select-item-hover !default;
$list-item-title-color: $gray-dark !default;
$list-item-title-hover: $primary !default;

// Popover
//
//
$popover-placement-gutter: 0.625rem !default;
$popover-shadow: 0 2px 8px rgba($gray-dark, 0.15);

// Tooltips
//
//
$tooltip-placement-gutter: 0.5rem !default;
$tooltip-bg: #404040 !default;
$tooltip-arrow-position: -0.3125rem !default;
$tooltip-max-width: 15.625rem !default;
$tooltip-spacer-x: 0.625rem !default; //10px
$tooltip-spacer-y: 0.3125rem !default; //5px

// Tree
//
//
$tree-theme-color: $primary !default;
$tree-hover-color: $gray-lightest !default;

// Timeline
//
//
$timeline-theme-color: $primary !default;
$timeline-gutter: 1.25rem !default;
$timeline-line-color: #ececec !default;
$timeline-dot-size: 0.625rem !default;
$timeline-dot-bg: $white !default;

// Tag
//
//
$tag-theme-color: $primary !default;
$tag-bg: $white !default;
$tag-height: 1.7rem !default;
$tag-line-height: 2.1 !default;
$tag-spacer: 0.625rem !default;
$tag-font-size: 0.75rem !default;

// Tabs
//
//
$tabs-theme-color: $primary !default;
$tabs-border: $border-color !default;
$tabs-gap: 1.25rem !default;
$tabs-arrow-color: $body-color !default;
$tabs-arrow-color-hover: $gray-dark !default;
$tabs-arrow-width: 2rem !default;
$tabs-toggler-padding-x: 1.25rem !default;
$tabs-toggler-padding-y: 0.75rem !default;
$tabs-toggler-sm: 0.8125rem !default;
$tabs-toggler-lg: 1rem !default;
$tabs-toggler-color: $gray-light !default;
$tabs-card-height: 2.5rem !default; // 40px
$tabs-card-toggler-bg: $gray-lightest !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.
$table-cell-padding: 0.75rem !default;
$table-sm-cell-padding: 0.3rem !default;
$table-bg: transparent !default;
$table-color: $body-color;
$table-head-bg: $white !default;
$table-head-color: $gray-dark !default;
$table-foot-bg: transparent !default;
$table-foot-color: $gray-dark !default;
$table-border-color: $gray-lighter !default;
$table-spacing: 0.9375rem !default;
$table-spacing-sm: 0.9375rem !default;
$table-spacing-lg: 0.9375rem !default;
$table-hover: #f0f0f0 !default;
$table-selected: rgba($primary, 0.1) !default;
$table-has-action-hover-bg: #f1f5f6 !default;
$table-column-sort-bg: $table-hover !default;
$table-border: $border-color !default;
$table-border-radius: $border-radius !default;
$table-column-sorter-size: 0.9375rem;
$table-row-expand-icon-size: 1.0625rem !default;

// Alerts
//
//
$alert-padding-x: 0.9375rem !default;
$alert-padding-y: 0.5rem !default;
$alert-padding-w-icon: 2.3125rem !default;
$alert-border-radius: $border-radius !default;
$alert-text-color: $body-color !default;

$alert-loop: (
  ant-alert-success $state-success-bg $state-success-text $state-success-border,
  ant-alert-info $state-info-bg $state-info-text $state-info-border,
  ant-alert-warning $state-warning-bg $state-warning-text $state-warning-border,
  ant-alert-error $state-danger-bg $state-danger-text $state-danger-border
);

// Modal
//
// Padding applied to the modal body
$modal-content-bg: $white !default;
$modal-content-shadow: 0 4px 12px rgba($black, 0.15) !default;
$modal-header-padding: 1rem 1.5rem !default;
$modal-border-radius: $border-radius !default;
$modal-body-padding: 1.5rem !default;
$modal-footer-padding: 0.625rem 1rem !default;
$modal-backdrop: rgba($black, 0.45) !default;

// Message
//
//
$message-content-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !default;

// Notification
//
//
$notification-width: 385px !default;

// Progress
//
//
$progress-height: 1rem !default;
$progress-font-size: 0.75rem !default;
$progress-bg: $gray-lighter !default;
$progress-border-radius: $border-radius !default;
$progress-box-shadow: inset 0 0.1rem 0.1rem rgba($black, 0.1) !default;
$progress-bar-color: $white !default;
$progress-bar-bg: $primary !default;

// Skeleton
//
//
$skeleton-color: $gray-lighter !default;
$skeleton-radius: 0 !default;

// Spin
//
//
$spin-color: $primary !default;

// Z-index master list
//
// of components dependent on the z-axis and are designed to all work together.
$zindex-default: 990 !default;
$zindex-dropdown-backdrop: 990 !default;
$zindex-navbar: 1000 !default;
$zindex-fixed: 1030 !default;
$zindex-sticky: 1030 !default;
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
$zindex-dropdown: 1050 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;

@import './variables/variables.scss';
@import './mixins/mixins.scss';

//*********************//
//**** Components ****//
//*******************//

// General
@import './components/general/typorgraphy';
@import './components/general/button';

// Layout
@import './components/layout/layout';

// // Navigation
// @import './components/navigation/affix';
// @import './components/navigation/breadcrumb';
@import './components/navigation/dropdown';
@import './components/navigation/menu';
// @import './components/navigation/page-header';
// @import './components/navigation/pagination';
// @import './components/navigation/steps';

// Data Entry
@import './components/data-entry/cascader';
// @import './components/data-entry/checkbox';
@import './components/data-entry/date-picker';
@import './components/data-entry/input';
// @import './components//data-entry/input-number';
@import './components/data-entry/form';
// @import './components/data-entry/mention';
// @import './components/data-entry/radio';
// @import './components/data-entry/rate';
// @import './components/data-entry/select';
// @import './components/data-entry/slider';
// @import './components/data-entry/switch';
// @import './components/data-entry/tree-select';
// @import './components/data-entry/time-picker';
// @import './components/data-entry/transfer';
// @import './components/data-entry/upload';

// Data Display
@import './components/data-display/avatar';
@import './components/data-display/badge';
@import './components/data-display/comment';
@import './components/data-display/collapse';
@import './components/data-display/carousel';
@import './components/data-display/card';
@import './components/data-display/calendar';
@import './components/data-display/descriptions';
@import './components/data-display/empty';
@import './components/data-display/list';
// @import './components/data-display/popover';
@import './components/data-display/statistic';
@import './components/data-display/tree';
// @import './components/data-display/tooltip';
@import './components/data-display/timeline';
@import './components/data-display/tag';
@import './components/data-display/tabs';
@import './components/data-display/table';

// Feedback
@import './components/feedback/alert';
@import './components/feedback/drawer';
@import './components/feedback/message';
@import './components/feedback/modal';
@import './components/feedback/notifications';
@import './components/feedback/progress';
@import './components/feedback/skeleton';
@import './components/feedback/spin';

// Other
@import './components/others/anchor';
@import './components/others/back-to-top';
@import './components/others/divider';

//****************************//
//**** Custom Components ****//
//**************************//

@import './custom-components/ellipsis-dropdown';
@import './custom-components/code-box';
@import './custom-components/api-container';
@import './custom-components/icon-display';

//****************************//
//********** Apps ***********//
//**************************//

@import './apps/mail';
@import './apps/chat';
@import './apps/calendar';
@import './apps//scrumboard';

//****************************//
//********* Vendors *********//
//**************************//

@import './vendors/apex-chart';
@import './vendors/quill';

//****************************//
//******** Template *********//
//**************************//

@import './template/header';
@import './template/side-nav';
@import './template/top-nav';
@import './template/logo';
@import './template/page-header';
@import './template/page-header-alt';
@import './template/nav-notification';
@import './template/nav-profile';
@import './template/content';
@import './template/nav-search';
@import './template/footer';


// 상단 오른쪽에 클릭 후 파란색 남아있는 부분 수정
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
    border-bottom: 2px solid transparent;
}

// preview 버튼 색상을 검정으로 변경, 헤더 높이 변경
.ant-image-preview-operations-operation {
    //margin-left: 12px;
    padding: 30px;
    cursor: pointer;
    color: black;
  }


// preview css
// https://github.com/18283995029/Web-SPOC/blob/07624f963cb28b6a004421aea855155c09c3b0c4/src/assets/scss/medisys/zimage.scss
